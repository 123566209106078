<template>
	<div id="wrap">
		<top page="knowledge" :showSearchButton="true" @search="handleSearch" />
		<div class="mobile-wrap">
			<div class="tab">
				<div class="mainBox">
					<a 
						v-for="category in categoryList" 
						:key="category.id"
						@click="changeCategory(category)"
						:class="category.id === selectedCategory.id ? 'on' : ''" 
					>
						{{category.value}}
					</a>
				</div>
			</div>
			<focus-pic category="花艺知识焦点图" />
			<div class="module-1">
				<div class="mainBox">
					<!-- 视频列表 -->
					<div class="list-box">
						<router-link 
							class="list"
							v-for="video in videoList"
							:key="video.id"
							:to="`/knowledge/detail/${video.id}`"
						>
							<div class="pic">
								<img :src="video.cover.absolutePath" />
								<span class="iconfont iconplay-fill1"></span>
							</div>
							<div class="con">
								<h2>{{video.courseName}}</h2>
								<span>{{video.docent}}</span>
								{{video.click}}人看过
							</div>
						</router-link>
					</div>
				</div>
				<div class="clear"></div>
				<div class="loadMore" v-if="paging.pageIndex < paging.pageCount">
					<a @click="handleLoadMore">+加载更多+</a>
				</div>
			</div>
		</div>
		<bottom />
		<side />
	</div>
</template>

<script>
import top from "./components/top.vue";
import bottom from "./components/bottom.vue";
import focusPic from "./components/focusPic.vue";
import side from "./components/side.vue";
import { wxShare } from "@/utils/share.js";
export default {
	page: {
		title: '首页'
	},
	data() {
		return {
			categoryList: [
				{
					id: 'all',
					value: '全部'
				}
			],
			selectedCategory: {
				id: 'all',
				value: '全部'
			},
			videoList: [],
			paging: {
                pageIndex: 1,
                pageSize: 50,
                recordCount: 0,
                pageCount: 0,
            },
			search: {
				courseName: ''
			}
		}
	},
	components: {
		top,
		bottom,
		side,
		focusPic,
	},
	methods: {
		handleWxShare() {
			let url = window.location.href

			this.$api.wechat.mpwechat.getJsapiSignature(url)
			.then(response => {
				if(response.code === '0') {

					let browser = window.navigator.userAgent.toLowerCase();	

					// 非微信浏览器不执行分享代码
					if (browser.match(/MicroMessenger/i) != 'micromessenger') {
						return;
					} 	

					//微信加签
                    let obj = {
                        appId: response.data.appId,
                        nonceStr: response.data.nonceStr,
                        signature: response.data.signature,
                        timestamp: response.data.timestamp,
                        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                    };


                    // var shareWxLink = encodeURIComponent(url);加密
                    let shareData = {
                        title: 'C-FLROAL线下活动报名', // 分享标题
                        desc: '名师全视频干货解析，当下全球最新流行的花艺技法与造型',
                        link: response.data.url,
                        imgUrl: 'http://www.c-floral.net/images/share/logo-02.jpg' // 分享图标
                    };

					//引用
                    wxShare(obj, shareData);
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		handleFindAllCategory() {
			this.$api.settings.dictionary.findAll('花艺知识')
			.then(response => {
				if(response.code === '0') {
					this.categoryList = this.categoryList.concat(response.data)
				}
				else {
					this.$message.error(response.error)
				}
			})
		},
		changeCategory(category) {
			this.selectedCategory = category;
			this.handleFindAll()
		},
		handleFindAll() {
			this.$api.aliyun.knowledge.findAll
			(
				this.search.courseName,
				this.selectedCategory.id === 'all' ? '' : this.selectedCategory.id,
				this.paging.pageIndex, 
				this.paging.pageSize
			)
			.then((response) => {
				if (response.code === "0") {
					this.videoList = response.data.videoList;
					this.paging = response.data.paging;
					this.$nextTick(() => {
						new this.$wow.WOW().init();
					});
				} else {
					this.$message.error(response.message);
				}
			});
		},
		handleLoadMore() {
            this.$api.aliyun.knowledge.findAll
			(	
				this.search.courseName,
				this.selectedCategory.id === 'all' ? '' : this.selectedCategory.id,
				this.paging.pageIndex + 1, 
				this.paging.pageSize
			)
			.then((response) => {
				if (response.code === "0") {
					this.videoList = this.videoList.concat(
						response.data.videoList
					);
					this.paging = response.data.paging;
					this.$nextTick(() => {
						new this.$wow.WOW().init();
					});
				} else {
					this.$message.error(response.message);
				}
			});
        },
		handleSearch(keywords) {
			this.search.courseName = keywords;
			this.handleFindAll()
		}
	},
	mounted() {
		this.handleWxShare()
		this.handleFindAllCategory()
		this.handleFindAll()
	}
}
</script>

<style src="../style/css/knowledge.css" scoped></style>
<style scoped>
</style>